import React, { useState } from "react";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';

export default function ScrollToTop() {

    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 200) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 200) {
            setShowScroll(false)
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: (window.pageYOffset - 600), behavior: 'smooth' });
    };

    window.addEventListener('scroll', checkScrollTop)

    return (
        <React.Fragment>
            <Button
                className="scrollTop disableOnPrint-scrollToTop"
                onClick={scrollTop}
                style={{ display: showScroll ? 'flex' : 'none' }}
                title="Scroll To Top"
            >
                <KeyboardArrowUpIcon
                    fontSize="large"
                    className="arrow-up"
                />
            </Button>
        </React.Fragment>
    );
}