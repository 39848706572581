import React, { useState, useEffect } from "react";
import CountdownTimer from "react-component-countdown-timer";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function TimeTicker(props) {
    const [open, setOpen] = useState(false);
    const [countDownTime, setCountDownTime] = useState(0);
    const handleClose = () => {
        setOpen(false);
    }

    const eventHandler = () => {
        setOpen(false);
        props.eventHandler();
    }

    window.addEventListener("mousemove", eventHandler);
    window.addEventListener("scroll", eventHandler);
    window.addEventListener("keydown", eventHandler);

    function timerInterval(loginFlag) {
        timerInterval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
            if (loginFlag == 1 && (expiredTime - Date.now()) <= 150000) {
                setCountDownTime(parseInt(expiredTime - Date.now()) / 1000);
                setOpen(true);
            }
            else {
                setCountDownTime(0);
                setOpen(false);
            }
        }, 1000);
    }

    useEffect(() => {
        if (props.loginDetails[0].LoginFlag != undefined && props.loginDetails[0].LoginFlag != null
            && props.loginDetails[0].LoginFlag != '' && props.loginDetails[0].LoginFlag == 1)
            timerInterval(props.loginDetails[0].LoginFlag);
    }, [props.loginDetails[0].LoginFlag]);

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                id="templateUploadSuccess"
            >
                <DialogContent>
                    <DialogContentText className="clr-dark-blue">
                        Your session will be expired in
                        <CountdownTimer
                            className="fw-bold clr-dark-blue"
                            count={countDownTime}
                            border={true}
                            showTitle={false}
                            noPoints={false}
                            responsive={true}
                            hideDay={true}
                            hideHours={true}
                            backgroundColor="#E8F8F8"
                        />
                        minutes. Please save the changes or data will be lost.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}